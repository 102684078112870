<template>
  <div>
    <CCard accentColor="primary">
      <CCardHeader>
        <slot name="header">
          <CIcon name="cil-grid" />
          {{title}}
        </slot>
      </CCardHeader>
      <CCardBody>
        <!-- kerja disini -->
        <CCollapse :show="formVisibility">
          <form-input ref="formCreate" />
        </CCollapse>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import formInput from "./form";
const fields = [
  { key: "number", label: "No" },
  { key: "nama_layanan", label: "Nama Layanan" },
  {
    key: "show_details",
    label: "Action",
    sorter: false,
    filter: false,
  },
];
export default {
  name: "AturMasaPersiapanPensiun",
  components: {
    formInput,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
      formVisibility: true,
      loading: false,
      page: 1,
      totalPage: 0,
      form: {
        nama_layanan: null,
      },
    };
  },
  mounted() {
    
  },
  computed: {
    title() {
      return "Atur Masa Persiapan Pensiun";
    },
    button_label() {
      return "Kembali";
    },
    icon_button() {
      return "cil-arrow-left";
    },
  },
  method: {
    addData(){
      this.$refs.formCreate.resetForm()
    },
  }
};
</script>
